<template>
  <div class="container-fluid">
    <div class="row my-3">
      <h1 class="col-sm-12 text-right">Solicitudes</h1>
    </div>

    <div class="row my-3">
      <div class="col-sm-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Mostrar solicitudes:</span>
          </div>
          <select v-model="solicitudes_filtradas" name="seleccionar_tipo_solicitudes" id="seleccionar_tipo_solicitudes" class="form-control">
            <option value="general">Normal</option>
            <option value="automatizada">Automatizada</option>
            <option value="todas">Todas</option>
          </select>
        </div>
      </div>
      <div class="col-sm-9 info text-right">
        Estatus solicitud: <span class="abierta">Abierta</span> <span class="aceptada">Aceptada</span> <span class="rechazada">Rechazada</span> <button @click="cargar_solicitudes" class="btn btn-secondary"><i class="fa-solid fa-rotate"></i> Recargar</button>
      </div>
    </div>

    <div v-if="!comite || !comite.solicitudes || comite.solicitudes.length == 0" class="solicitudes">
      <div>No hay solicitudes por el momento</div>
    </div>
    <div v-else class="solicitudes">
      <div v-for="solicitud in solicitudes_generales" :class="'solicitud my-2'+tipo_solicitud(solicitud)">
        <div class="header">
          <div class="id_solicitd"><strong>Solicitud ID:</strong><br/>{{ solicitud.solicitud_id }}</div>
          <div class="nombre"><strong>Nombre:</strong><br/>{{ solicitud.nombre }}</div>
          <div class="monto"><strong>Monto:</strong><br/>${{ $helper.moneyFormat(solicitud.monto_original) }}</div>
          <div class="tasa"><strong>Tasa:</strong><br/>{{ solicitud.tasa_interes_original }}%</div>
          <div class="plazo"><strong>Plazo:</strong><br/>{{ solicitud.plazo_original }}</div>
          <div class="aforo"><strong>Aforo:</strong><br/>{{ solicitud.aforo_original }} / 1</div>
          <div class="control"><router-link :to="{name: 'analyticpoint-solicitudes-solicitud', params:{solicitud: solicitud.id}}" class="btn btn-light">Revisar</router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/apps/analyticpoint/api/comite/comite';

  export default {
    data() {
      return {
        comite: {
          solicitudes: []
        },
        solicitudes_filtradas: 'general'
      }
    },
    mounted() {
      this.cargar_solicitudes();
    },
    methods: {
      async cargar_solicitudes() {
        try {
          this.comite = (await api.obtener_comite_activo()).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },
      estatus_solicitud(solicitud) {
        if (!solicitud.show)
          return 'cerrado';

        return 'abierto';
      },
      tipo_solicitud(solicitud) {
        let tipo_class = '';
        solicitud.votos.forEach(voto => {
          if (tipo_class == '' && voto.account_id == this.$auth.getUser().id)
            tipo_class = voto.resolucion == 'aceptado' ? ' solicitud_aceptada' : ' solicitud_rechazada';
        });

        return tipo_class;
      }
    },
    computed: {
      solicitudes_generales() {
        let solicitudes = [];

        if (!this.comite || !this.comite.solicitudes)
          return solicitudes;

        this.comite.solicitudes.forEach(solicitud => {
          if (this.solicitudes_filtradas == 'general' && ['normal','pre_automatizada'].indexOf(solicitud.tipo) != -1)
            solicitudes.push(solicitud);

          if (this.solicitudes_filtradas == 'automatizada' && solicitud.tipo == 'automatizada')
            solicitudes.push(solicitud);

          if (this.solicitudes_filtradas == 'todas')
            solicitudes.push(solicitud);
        })

        return solicitudes;
      }
    }
  }
</script>

<style lang="scss" scoped>
  $solicityd_abierta: #0781cd;
  $solicitud_votada: #16af30;
  $solicitud_rechazada: #c71f1f;
  .info {
    width: 90%;
    margin: auto;

    span {
      //width: 20px;
      padding: 5px 10px;
      color: #fff;
      border-radius: 4px;
      display: inline-block;
    }

    span.abierta {
      background-color: $solicityd_abierta;
    }

    span.aceptada {
      background-color: $solicitud_votada;
    }

    span.rechazada {
      background-color: $solicitud_rechazada;
    }
  }
  .solicitudes {
    .solicitud {
      background-color: $solicityd_abierta;
      // width: 90%;
      margin: auto;
      color: #fff;
      padding: 20px 15px;
      border-radius: 5px;

      .header {
        display: flexbox;

        .id_solicitd,.nombre,.monto,.tasa,.plazo,.aforo,.control {
          width: 10%;
          display: inline-block;
        }

        .nombre {
          width: 43%;
        }

        .control {
          width: 5%;
          text-align: center;

          i {
            transform: rotate(90deg);
            cursor: pointer;
          }
        }
      }
    }

    .solicitud_aceptada {
      background-color: $solicitud_votada;
    }

    .solicitud_rechazada {
      background-color: $solicitud_rechazada;
    }
  }

@media screen and (max-width: 576px) {
  .solicitudes {
    .solicitud {

      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 100%;
          text-align: right;
          display: block;

          strong {
            float: left;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 577px) and (max-width: 667px) {
  .solicitudes {
    .solicitud {
      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 20%;
        }

        .nombre {
          width: 45%;
        }

        .control {
          width: 15%;
        }
      }
    }
  }
}

@media screen and (min-width: 668px) and (max-width: 1180px) {
  .solicitudes {
    .solicitud {
      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 20%;
        }

        .nombre {
          width: 50%;
        }

        .control {
          width: 10%;
        }
      }
    }
  }
}

@media screen and (min-width: 668px) and (max-width: 1180px) and (orientation: portrait) {
  .solicitudes {
    .solicitud {
      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 20%;
        }

        .nombre {
          width: 45%;
        }

        .control {
          width: 10%;
        }
      }
    }
  }
}
</style>